import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Cancer.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Cancer Relationship
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/cancer"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Cancer</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>             
              <h4 className="text-sm"></h4>

              <h4 className="text-sm">Cancer Relationship</h4>
              <h4 className="mx-2 text-sm"></h4>             
              <h4 className="text-sm"></h4>


            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Cancer Relationship </h1>
                <h2 className="text-sm md:text-base ml-4">Jun 22 - Jul 22</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Cancer Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Cancer Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Cancer Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-love");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-nature");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-man");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-traits");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/cancer/cancer-facts");
                  }}>
                  <h5 className="font-semibold text-black">Cancer Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
           <p className="playfair text-black text-xl md:text-3xl">Cancer- Relationships</p><br/>
           Nothing makes Cancers feel more alive than relationships. They don't build relationships quickly. Before revealing your true feelings, you need time to check yourself. You are honest and caring, and usually exude an aura of openness to you. In a Cancer relationship, this is a huge benefit for obvious reasons. Your partner needs to be someone you can trust and be honest and open with. Maintaining a relationship with a Cancer person is not easy. Because it hides all adversity and shows that you are always passionate and excited even when you are completely overwhelmed and overwhelmed. You never give up and always try to make relationships work despite many problems. <br/>
           <br></br>
           Learn about potential strengths that can help improve your relationships with JanamPatri's guidance. <br/>
           <br></br>
           <p className="playfair text-black text-xl md:text-3xl">Cancer as a lover</p><br/>
           You are a very emotional, sensitive, sensual, and caring lover. We take security very seriously, so we actively protect our partners. You protect them and give them security in all situations throughout their lives. Vulnerable and takes time to recover. You are deeply rooted in memory and live in a fantasy world. It is very intense and affects the current situation and external things. Your strong imagination will help you see into the future and your love story will be unique and interesting. They are respectful, very loyal, devoted, and consistent in their relationships. <br/>
           <br></br>
           Therefore, you love long-term partners. You are a passionate lover and do everything selflessly. You are constantly concerned about your partner's needs and desires above you. Your intuition is very strong and it helps to be gentle and soft. You don't believe in superficial love. <br/>
           <br></br>
           Therefore, you surround your partner deeply with the full force of your emotions. You strive to build lifelong and valuable relationships. Even if the relationship isn't going well, they will try everything to make it happen until they are completely overwhelmed and broken. <br/>
           <br></br>
           Gain insight into your love life with our FREE Love Horoscope Report!<br/>
           <br></br>
           <p className="playfair text-black text-xl md:text-3xl">Cancer as a Colleague</p><br/>
           You are loyal, honest, and steadfast, but at the same time very sentimental and emotional. You are productive, kind, empathetic, and intuitive, and you want your employees to feel comfortable at work. You are a team worker with a high level of intelligence and a natural desire to care for all your teammates. You shine in such a work environment.<br/>
           <br></br>
           When you are very happy, you bring warmth and a  spirit to your community. They find it difficult to deal witpositiveh stress, criticism, and insults. In the face of criticism or when someone insults you, you become cold, resilient, and reserved. Prefer to stay in your shell for a while. They take a long time to recover. When their emotional needs are met, they can be the most loyal and honest employees. <br/>
           <br></br>
           <p className="playfair text-black text-xl md:text-3xl">Cancer as a friend</p><br/>
           You are naturally loyal, and nurturing and believe in quality friendships. Ideas for entertaining and amusing your friends will come naturally. Love your friends like you love your family. you always have them close to your heart. They often invite them to dinner parties, family gatherings, reunion plans, and get-togethers with friends. Make your friends feel good by caring about their lives and supporting them through the ups and downs. Therefore, you are a good friend to them. This is the secret to winning and influencing their hearts. You are very selfless and will do anything for your friends. you made them feel good. You are curious and always want to help your friends in need. You are not judgmental and often listen or empathize with them. <br/>
           <br></br>
           Friends often pour out their hearts because you listen patiently to problems big and small. After all, every friendship is unique. However, according to astrology, some sun signs go well with other zodiac signs. So who are your best friends and worst friends?<br/>
           <br></br>
           <p className="playfair text-black text-xl md:text-3xl">Cancer as a Boss</p><br/>
           You have a very careful and conscientious approach to your profession. He shows a serious and strict attitude to his friends, hiding his sensitive and kind personality. They usually avoid fun and entertainment during office hours and do not allow team members to do the same. They have a very sharp memory and are very careful in observing, analyzing, and evaluating the performance of their subordinates. <br/>
           <br></br>
           You understand the intentions and feelings of your subordinates very well. You have a habit of reading between the lines, so your subordinates cannot hide or lie to you. Carefully monitor your teammates' behavior and earn points for it. You are smart enough to expose mistakes, secrets, mistakes, and lies. It's tough on unfocused team members. They are usually generous with salaries, raises, and bonuses. We also listen patiently to team members' problems and complaints. <br/>
           <br></br>
           Are you facing tension with your boss? Get personalized reports and solutions for your boss and you from the best and most trusted astrologers. <br/>
           <br></br>
           <p className="playfair text-black text-xl md:text-3xl">Cancer Father</p><br/>
           Your biggest wish in life is to be a super cool and inspiring dad. Be a child while playing with the child, understand the child's feelings, and always protect them. You are naturally family-oriented and willing to take on any challenge that children throw at you. Therefore, you are the best father figure for your children. They take pride in their parenting and are always there for them and never miss a moment with them. <br/>
           <br></br>
           You are on top to fulfill their responsibilities by going back and forth between You are very gentle, kind, loving, creative, kind, gentle, patient, and a good nurturer. Just like your kids, you will pick up new games in no time. You love and enjoy the games they come up with. You won't be hard on your children if they worry about you and never show negative feelings towards them. You will do everything so that they don't forget you.<br/>
           <br></br>
           <p className="playfair text-black text-xl md:text-3xl">Cancer as a Mother</p><br/>
           You are the epitome of motherhood. You are an overprotective and overbearing mother who raises her child without help. You are the perfect mom and love every moment of motherhood. You are always there for the kids and save time in their hectic schedules when they need you. You’re very happy to do anything for your children. Very gentle and graceful when talking to children. You know very well how to stay calm in stressful and problematic situations, and you never show it to your children. You never tell them you are stressed or throw a tantrum. <br/>
           <br></br>
           Sometimes you find it difficult to "let go" and motivate your children to stand up. Want to know how your zodiac sign helps with parenting? Learn parenting styles from top astrologers based on your zodiac sign. <br/>
           <br></br>
           <p className="playfair text-black text-xl md:text-3xl">Childhood cancer</p><br/>
           They are most comfortable at home, surrounded by family and surrounded by familiar sights, sounds, and smells. You live an adventurous life at home. We value tradition and everyday life at home. These children are emotional, sensitive, protective, cautious, and don't show their emotions easily. Chaotic and noisy environments and situations can easily overwhelm you. You love to be in a stable environment that calms down and calms down, and you seek emotional security.<br/>
           <br></br>
           Children with Cancer are moody and their behavior depends on their mood. They are quiet, shy, and very attached to their parents. You are highly emotional, sensitive, empathetic, and loving to others. They can eat emotionally and will continue to eat when they feel stressed, upset, or anxious. Children are very intuitive, observant, and have excellent memory. They are more satisfied with books, movies, and web series than others.<br/>
           <br></br>
           <p className="playfair text-black text-xl md:text-3xl">Cancer as a husband</p><br/>
           <p className="font-bold text-orange-500 inline-block mr-2">Best for marriage:</p>Taurus, Virgo, Scorpio, Capricorn, and Pisces women. <br/>
           <br></br>
           You are a sensitive, shy, and emotional person. They are security-obsessed and do not trust easily. What you need most in life is stability and security with your home and family. You are sensitive, empathetic, protective, honest, and truly devoted to your spouse. You are very romantic, never boastful, and sentimental, and they thank you in many ways for having a loving and long-lasting marriage. Your greatest joy and desire in life is to transform your home into a cozy, warm, and nurturing environment. to have a good family environment. <br/>
           <br></br>
           To truly shine in life, you need female validation. Your wife will thank you for always being supportive and never forgetting her birthdays and anniversaries. You assume the role of head of the family, addressing everyone's needs, problems and desires. You are afraid of losing people you love and care so much about. <br/>
           <br></br>
           Newlyweds and worried about the challenges you face in your marriage? Take some cold medicine and research the best astrological records of your marriage.  <br/>
           <br></br>
           <p className="playfair text-black text-xl md:text-3xl">Cancer as a Wife</p><br/>
           <p className="font-bold text-orange-500 inline-block mr-2">Best zodiac signs for marriage:</p>Taurus, Virgo, Scorpio, Capricorn, Pisces. <br/>
           <br></br>
           You are a highly imaginative, intuitive, and imaginative person. You are such a lovely, humorous, loyal, and caring wife. You like compliments and hate when family members criticize you. Your emotions are banished and you become very soft and gentle from the inside. You expect your husband to be very kind and gentle with you. You are patient, versatile, always happy, and happy with whatever finances your husband can bring home. <br/>
           <br></br>
           Your cheerful nature is greatly appreciated by your close ones. You are shy and like to live by your precepts. You often feel insecure and expect your husband to give you hope and encouragement from time to time. You need respect, appreciation, and pampering from your partner. They value their home and family very much and want them to be happy all the time. You work tirelessly all day just to keep your family happy.<br/>
           <br></br>
           To know more, Bodhi is here to help you out.<br/>
           <br></br>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
